import { memo, useCallback, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./SmartDPSearch.scss";
import { sendTagOnSDPSearchPage } from "app/utils/analytics";
import { DynamicBlocksRendererContainer } from "app/pages/.shared/DynamicBlocks";
import { PushNotificationContainer } from "app/pages/.shared/PushNotification/PushNotificationContainer";
import SmartDPMarketingHeroImageContainer from "app/pages/SmartDP/SmartDPMarketingHeroImage/SmartDPMarketingHeroImageContainer";
import SmartDPMarketingHeadlineContainer from "app/pages/SmartDP/SmartDPMarketingHeadline/SmartDPMarketingHeadlineContainer";
import SmartDPSearchFormContainer from "app/pages/SmartDP/Search/SDPSearchForm/SmartDPSearchFormContainer";
import { buildSDPSearchPayload } from "app/utils/smartDPUtils";
import { useNavigate } from "react-router-dom";
import HomeSmartDPSearchInput from "app/pages/SmartDP/Search/SDPSearchForm/HomeSmartDPSearchInput";
import { useFsFlag } from "@flagship.io/react-sdk";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import HeaderContainer from "app/pages/Header/HeaderContainer";
import classNames from "classnames";
import SmartDPSearchHeadMetadata from "app/pages/SmartDP/Search/SmartDPSearchHeadMetadata";

const SmartDPSearch = ({
	homeBlocks = [],
	resetAllSDPProductsFilter,
	smartDPRef = {},
	isBrandWithCustomHeader,
	isTransparentHeader,
	isStopover,
}) => {
	const navigate = useNavigate();
	const showMiniSDPFormFlag = useFsFlag("sdp_show_mini_form_mobile", false);
	const showMiniSDPForm = showMiniSDPFormFlag.getValue();

	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;
	const smartDPSearchRef = useRef(null);

	const smartDPFormClass = classNames("smartdp-search__form", {
		"smartdp-search__form--mini-version": showMiniSDPForm && isMobile,
	});
	useEffect(() => {
		sendTagOnSDPSearchPage();
	}, []);

	const handleFormSubmission = useCallback(
		data => {
			const queryParams = buildSDPSearchPayload(data);

			resetAllSDPProductsFilter();

			navigate({
				pathname: isStopover ? "/stopover/listing" : "/sdp/listing",
				search: queryParams,
			});

			// window.open(`${shop}/sdp/listing?${queryParams}`, "_blank");
		},
		[isStopover]
	);

	const themeFlag = useFsFlag("header_orange_use_custom", undefined);
	const useHeaderOrange = themeFlag.getValue();

	const containerClass = classNames({
		"smartdp-search__container": true,
		"smartdp-search__container--custom-header":
			isBrandWithCustomHeader || useHeaderOrange || !isTransparentHeader,
	});

	return (
		<div ref={smartDPSearchRef} className="smartdp-search">
			<SmartDPSearchHeadMetadata />
			<HeaderContainer />
			<div className={containerClass}>
				<div className="smartdp-search__image">
					<div className="smartdp-search__image-background">
						<SmartDPMarketingHeroImageContainer />
					</div>
					<div className="smartdp-search__image-headline">
						<SmartDPMarketingHeadlineContainer />
						<div className={smartDPFormClass}>
							{showMiniSDPForm && isMobile ? (
								<HomeSmartDPSearchInput
									smartDPRef={smartDPRef}
									onSuccess={handleFormSubmission}
									showMiniSDPForm
								/>
							) : (
								<SmartDPSearchFormContainer
									onSuccess={handleFormSubmission}
									disableFormPersistance
									isStopover={isStopover}
								/>
							)}
						</div>
					</div>
				</div>
				<div className="smartdp-search__content">
					<div className="smartdp-search__dynamic-blocks">
						<DynamicBlocksRendererContainer blocks={homeBlocks} />
					</div>
				</div>
			</div>
			<PushNotificationContainer />
		</div>
	);
};

SmartDPSearch.propTypes = {
	homeBlocks: PropTypes.array,
	resetAllSDPProductsFilter: PropTypes.func,
	smartDPRef: PropTypes.object,
	isStopover: PropTypes.bool,
	isBrandWithCustomHeader: PropTypes.bool,
	isTransparentHeader: PropTypes.bool,
};

export default memo(SmartDPSearch);
